@use 'angular-material-css-vars' as mat-css-vars;

@include mat-css-vars.init-material-css-vars();

// Variables for the default Angular theme
@import "default-theme";


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: white;
}

.screen-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.full-width {
    width: 100%;
}

mat-step-header {
    margin-bottom: 5px;
}

.success {
    color: var(--success-color);
}

.warning {
    color: var(--warning-color);
}

// The current theme can override anything
@import "current-theme";